import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';
import { BuilderComponent } from '@builder.io/react';
import '@builder.io/widgets';

import './index.css';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
        <Route path="/" exact component={Home}/>         
          <Route render={({ location }) => <CatchallPage key={location.key} />} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

class CatchallPage extends React.Component {
  state = { notFound: false };

  render() {
    return !this.state.notFound ? (
      <BuilderComponent
        apiKey="1632ddd7e4f04b9c9eb5d340e0da997c"
        model="page"
        contentLoaded={content => {
          if (!content) {
            this.setState({ notFound: true });
          }
        }}
      >
        <div className="loading">Loading...</div>
      </BuilderComponent>
    ) : (
      <NotFound /> // Your 404 content
    );
  }
}

const Home = () => <Redirect to="/ninja"/>
const NotFound = () => <h1>No page found for this URL, did you publish it?</h1>;

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
